<template>
  <div class="container">
    <div class="image">
      <b-img
        src="@/assets/images/icons/check-circular.png"
        width="106"
        :class="efect"
        center
      />
      <br>
      <div class="text text-center">
        <strong>Cadastro criado com <br> sucesso.</strong>
      </div>
    </div>
  </div>
</template>

<script>

import {
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },

  data() {
    return {
      time: 3,
      width: 106,
      efect: '',
    }
  },

  mounted() {
    const timer = setInterval(() => {
      if (this.time > 0) {
        this.time -= 1
        if (this.time === 2) {
          this.efect = 'efect'
        }
      } else {
        clearInterval(timer)
        this.status = false
        this.$router.push('/')
      }
    }, 1000)
  },

}
</script>

<style scoped>
  .container {
    width: 100%;
    height: 100%;
    max-width: 100%;
    background: #0058FF;
    display: block;
    position: absolute;
  }
  .image {
    position: absolute;
    left: calc(50% - 120px);
    top: 45%;
  }
  .efect {
    transition: transform 5s;
    transform: translateX(10px) scale(1.2);
  }
  .text {
    color: #FFFFFF;
    font-size: 24px;
  }
</style>
